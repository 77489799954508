import React, { Fragment, useState, useRef, useEffect, useContext } from "react"
import { Form, Formik } from "formik"
import { navigate } from "gatsby"

import Message from "elements/Message"
import CartLayout from "layout/CartLayout"
import UploadDropzone from "./UploadDropzone"
import UploadGuidelines from "./UploadGuidelines"
import ActionButtons from "elements/ActionButtons"
import UploadGuidelinesModal from "./UploadGuidelines/UploadGuidelinesModal"
import UploadErrorNotification from "./UploadDropzone/UploadErrorNotification"

import { isIeOrEdge } from "../../services/browserCompatibility"
import { uploadDocument } from "./services/uploadDocuments"
import { generateFormField } from "../Elements/Form/services/form"
import { parseFormField } from "../../services/airtable"
import { generateInitialValues } from "../Epharmacy/services/checkout"

import acceptedFileTypes from "./utils/acceptedFileTypes.json"
import { AppContext } from "../../context/AppContext"
import { EpharmacyContext } from "../Epharmacy/EpharmacyContext/EpharmacyContext"

const Upload = ({
  pageContext,
  maxFileSize,
  maxFileCount,
  guidelines,
  icon,
  dropzoneLabel,
  cartContainer,
  documents,
  medicines,
  dispatch,
  alreadyHasButtons,
  type,
  moduleName,
}) => {
  const { dispatch: appDispatch } = useContext(AppContext)
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const fileInputRef = useRef(null)
  const [notifications, setNotifications] = useState([])
  const [filesUploaded, setFilesUploaded] = useState(documents)

  const isValid = filesUploaded.length > 0
  const checkIfButtonShouldBeDisabled = (values) => {
    let shouldBeDisabled = true
    if (type === "epharmacy-prescription") {
      for (let i = 0; i < filesUploaded?.length; i++) {
        let name = filesUploaded[i]?.name
        name = name.substring(0, 2)
        if (name === "rx") {
          shouldBeDisabled = false
          break
        }
      }

      if (medicines?.length === 0) {
        shouldBeDisabled = true
      }

      const prescriptionDate = values?.prescriptionDate
      const isPrescriptionDateInvalid =
        prescriptionDate.date.value === "" ||
        prescriptionDate.month.value === "" ||
        prescriptionDate.year.value === ""

      return shouldBeDisabled || isPrescriptionDateInvalid
    } else {
      return !isValid
    }
  }

  useEffect(() => {
    setFilesUploaded(documents)
  }, [documents])

  const handleFileChooser = () => {
    appDispatch({ type: "HIDE_MODAL" })
    fileInputRef.current.click()
  }

  const openFileChooser = () => {
    if (filesUploaded.length === 0) {
      appDispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Upload Guidelines",
          isCard: true,
          hideCloseButton: true,
          isActive: true,
          // isFullheight: true,
          headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-4 has-text-centered`,
          content: (
            <UploadGuidelinesModal handleCloseModal={handleFileChooser} />
          ),
        },
      })
    } else if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
    else handleFileChooser()
  }

  const handleAlreadyUploaded = (setNotifications) => (file) => {
    setNotifications((notificationsList) => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const closeNotifications = () => {
    setNotifications([])
  }

  const checkInvalidFileType = ({ filesUploaded }) => {
    return filesUploaded.every((file) => {
      return !acceptedFileTypes.includes(file.type)
    })
  }

  const handleFileRead = (event) => {
    closeNotifications()
    const tempFilesUploaded = [...event.target.files]
    const initialNotifications = []
    const hasExceededMaxFiles =
      tempFilesUploaded.length + filesUploaded.length > maxFileCount

    const hasInvalidFileType = checkInvalidFileType({
      filesUploaded: tempFilesUploaded,
    })
    switch (true) {
      case hasExceededMaxFiles:
        initialNotifications.push(
          <UploadErrorNotification
            message={`Please upload only a maximum of ${maxFileCount} files.`}
          />
        )
        break
      case hasInvalidFileType:
        initialNotifications.push(
          <UploadErrorNotification
            message={`You may only upload files in the following format: jpeg, jpg, png, or pdf files.`}
          />
        )
        break
      default:
        for (let i = 0; i < tempFilesUploaded.length; i++) {
          if (tempFilesUploaded[i].size < maxFileSize)
            uploadDocument(
              tempFilesUploaded[i],
              filesUploaded,
              setFilesUploaded,
              (prevFilesUploaded) => {
                dispatch({
                  type: "SAVE_DOCUMENTS",
                  payload: [...prevFilesUploaded],
                })
              },
              handleAlreadyUploaded(setNotifications),
              type === "patient" ? "id" : "rx"
            )
          else
            initialNotifications.push(
              <UploadErrorNotification
                fileName={tempFilesUploaded[i].name}
                message="is greater than 5MB. Please upload a file or photo less than 5MB."
              />
            )
        }
        break
    }

    setNotifications(initialNotifications)
  }

  const formFields = pageContext.formFields
  const parsedFormFields = parseFormField(formFields)[0]

  const handleSubmit = async (values) => {
    await epharmacyDispatch({
      type: "SAVE_EPHARMACY",
      payload: { prescriptionDate: values.prescriptionDate },
    })

    navigate(pageContext.nextPath)
  }

  return (
    <Formik
      initialValues={generateInitialValues({ epharmacyState })}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, setFieldError, isValid }) => (
        <Form>
          <CartLayout
            isCentered
            desktop={10}
            fullhd={type === "patient" ? 12 : 8}
            cartContainer={cartContainer}
            isPatientEnrollmentUpload={type === "patient" ? true : false}
          >
            <UploadGuidelines guidelines={guidelines} />
            <Message color="warning">
              Upload your prescription and our pharmacist will confirm the total
              price and medicine assistance through a message. Orders are not
              final until our pharmacist verifies your order.
              <br />
              <br />
              Starting February 2020, MedGrocer will no longer serve orders with
              prescriptions indicating cutting tablets into half (e.g.
              Sacubitril-Valsartan 200mg tablet, 1/2 tab twice a day). Please
              visit your doctor to get an updated prescription for the whole
              tablet intake.
            </Message>
            <UploadDropzone
              label={dropzoneLabel}
              icon={icon}
              fileInputRef={fileInputRef}
              handleFileChooser={openFileChooser}
              handleFileRead={handleFileRead}
              notifications={notifications}
            />
            {!isValid && (
              <p className="help has-text-danger">
                {type === "patient"
                  ? "Please upload your ID."
                  : "Please upload your prescription."}
              </p>
            )}
            {generateFormField({
              formFields: parsedFormFields.fields,
              formField: parsedFormFields.fields[0],
              values,
              setFieldValue,
              setFieldError,
            })}
          </CartLayout>
          {alreadyHasButtons ? null : (
            <ActionButtons
              back={{ label: "Back", link: pageContext.backPath }}
              submit={{
                label: "Next",
                link: pageContext.nextPath,
                disabled: checkIfButtonShouldBeDisabled(values),
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

export default Upload
